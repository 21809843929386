import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAward, faShower } from "@fortawesome/free-solid-svg-icons"

import { HistoryTableCell } from "./utils"

export const GameHistory = ({ state }) => {
  return (
    <div className="section">
      <h3 className="title is-5">Spielverlauf</h3>
      <div className="table-container">
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>Runde:</th>
              {state.successfulPlayers.map((player) => (
                <th>
                  {player.name} <FontAwesomeIcon icon={faAward} />
                </th>
              ))}
              {state.players.map((player) => (
                <th>{player.name}</th>
              ))}
              {state.showeringPlayers.map((player) => (
                <th>
                  {player.name} <FontAwesomeIcon icon={faShower} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: state.round - 1 }).map((round, index) => (
              <tr
                className="has-text-centered"
                key={`history-${round}-${index}`}
              >
                <td>{index + 1}</td>
                {state.successfulPlayers.map((player, i) => (
                  <td key={`successful-${player.name}-${i}-history`}>
                    {player.history[index] && (
                      <HistoryTableCell {...{ player, index }} />
                    )}
                  </td>
                ))}
                {state.players.map((player, i) => (
                  <td key={`unsuccessful-${player.name}-${i}-history`}>
                    {player.history[index] && (
                      <HistoryTableCell {...{ player, index }} />
                    )}
                  </td>
                ))}
                {state.showeringPlayers.map((player, i) => (
                  <td key={`showering-${player.name}-${i}-history`}>
                    {player.history[index] && (
                      <HistoryTableCell {...{ player, index }} />
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
