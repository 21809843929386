import React from "react"

import { PreGamePlayercard } from "./pre-game-playercard"

export const PrepareGameControls = ({ state, setState }) => {
  const handleNameInput = (event) => {
    setState({ ...state, newPlayer: event.target.value })
  }

  const handleEnter = (e) => {
    if (e.keyCode === 13 && state.newPlayer.length >= 1) {
      addPlayer()
    } else if (
      e.keyCode === 13 &&
      state.newPlayer.length === 0 &&
      state.players.length > 1
    ) {
      startGame()
    }
  }

  const addPlayer = () => {
    const newPlayers = state.players.concat({
      name: state.newPlayer,
      points: 0,
      showerCounter: 0,
      history: [],
      wonInRound: 0,
    })

    setState({
      ...state,
      round: 0,
      players: newPlayers,
      newPlayer: "",
    })
  }

  const startGame = () => {
    setState({
      ...state,
      prepareGameControlsActive: false,
      gameWrapper: "",
    })
    sessionStorage.setItem(0, JSON.stringify(state))
    sessionStorage.setItem("lastKey", JSON.stringify(state))
  }

  return (
    <div>
      <div className={`level `}>
        <div className="level-item">
          <input
            type="text"
            className={`input `}
            value={state.newPlayer}
            onChange={handleNameInput}
            placeholder="Name"
            onKeyDown={handleEnter}
          />
        </div>
        <div className="level-right">
          <div className="level-item">
            <button
              className={`button is-primary `}
              disabled={state.newPlayer.length < 1}
              onClick={addPlayer}
            >
              Spieler hinzufügen
            </button>
            <div className="level-item">
              <button
                className={`button is-warning`}
                disabled={state.players.length < 2}
                onClick={startGame}
              >
                {" "}
                Spiel starten
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={`columns is-multiline`}>
        {state.players.map((player) => (
          <PreGamePlayercard {...{ state, setState, player }} />
        ))}
      </div>
    </div>
  )
}
