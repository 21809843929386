import React from "react"

export const AbortGameModal = ({ state, setState, closeAbortGameModal }) => {
  const abortGameFunction = () => {
    setState({ ...state, abortGame: true, abortGameModalActive: false })
    sessionStorage.clear()
  }

  return (
    <div className={`modal ${state.abortGameModalActive ? "is-active" : ""}`}>
      <div
        className="modal-background"
        onClick={closeAbortGameModal}
        onKeyDown={() => {}}
        role="button"
        tabIndex="0"
        aria-label="close image modal"
      ></div>
      <div className="modal-content">
        <div className="box has-text-centered">
          <p className="title is-6">
            Möchtest du das Spiel tatsächlich abbrechen?
          </p>
          <div className="buttons is-centered">
            <button
              className="button is-danger is-fullwidth is-large"
              onClick={abortGameFunction}
            >
              Ja
            </button>

            <button
              className="button is-info is-fullwidth is-large"
              onClick={closeAbortGameModal}
            >
              Nein
            </button>
          </div>
        </div>
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={closeAbortGameModal}
      ></button>
    </div>
  )
}
