import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShower } from "@fortawesome/free-solid-svg-icons"
import Layout from "components/site-structure/layout"
import SEOComponent from "components/seo"

import {
  AbortGameModal,
  ShowerModal,
  StarterModal,
  WinnerModal,
} from "components/spielen/modals"

import { GameHistory, PlayerRow, PrepareGameControls } from "components/spielen"

const Spielen = () => {
  const [state, setState] = useState({
    players: [],
    move: 1,
    round: 1,
    showeringPlayers: [],
    successfulPlayers: [],
    playersTurn: 0,
    newWinner: "",
    newPlayer: "",
    prepareGameControlsActive: true,
    gameWrapper: "is-hidden",
    showerModalActive: false,
    starterModalActive: false,
    newShoweringPlayer: "",
    winnerModalActive: false,
    abortGame: false,
    abortGameModalActive: false,
    settings: {
      showWinner: true,
      showShowerer: true,
      showStarter: true,
      randomize: true,
    },
  })

  useEffect(() => {
    if (sessionStorage.getItem("lastKey")) {
      setState(JSON.parse(sessionStorage.getItem("lastKey")))
    }
  }, [])

  const restartGame = () => {
    let newPlayers = state.players
      .concat(state.successfulPlayers, state.showeringPlayers)
      .map((player) => ({
        ...player,
        points: 0,
        showerCounter: 0,
        history: [],
        wonInRound: 0,
      }))

    setState({
      players: newPlayers,
      move: 1,
      round: 1,
      showeringPlayers: [],
      successfulPlayers: [],
      playersTurn: 0,
      newWinner: "",
      newPlayer: "",
      prepareGameControlsActive: true,
      gameWrapper: "is-hidden",
      showerModalActive: false,
      starterModalActive: false,
      newShoweringPlayer: "",
      winnerModalActive: false,
      abortGame: false,
      abortGameModalActive: false,
    })
    sessionStorage.clear()
  }

  const rewindLastMove = () => {
    setState(JSON.parse(sessionStorage.getItem(state.move - 1)))
    sessionStorage.removeItem(state.move - 1)
    sessionStorage.setItem("lastKey", JSON.stringify(state))
  }

  const closeShowerModal = () =>
    setState({ ...state, showerModalActive: !state.showerModalActive })

  const closeWinnerModal = () =>
    setState({ ...state, winnerModalActive: !state.winnerModalActive })

  const closeStarterModal = () =>
    setState({ ...state, starterModalActive: !state.starterModalActive })

  const closeAbortGameModal = () =>
    setState({ ...state, abortGameModalActive: !state.abortGameModalActive })

  const toggleAboortModal = () => {
    setState({
      ...state,
      abortGameModalActive: true,
    })
  }

  return (
    <Layout>
      <SEOComponent title="Spielen" />
      <ShowerModal {...{ state, closeShowerModal }} />
      <WinnerModal {...{ state, closeWinnerModal }} />
      <StarterModal {...{ state, closeStarterModal }} />
      <AbortGameModal {...{ state, setState, closeAbortGameModal }} />

      <div className="container">
        <div className="section px-0">
          <h1
            className={`title is-3 has-text-centered ${
              state.prepareGameControlsActive ? "" : "is-hidden"
            }`}
          >
            Mölkky Online Spielen
          </h1>
          {state.prepareGameControlsActive ? (
            <PrepareGameControls {...{ state, setState }} />
          ) : null}
          {state.players.length && state.round < 27 && !state.abortGame ? (
            <div className={state.gameWrapper}>
              <div className="columns is-centered">
                <div className="column is-10">
                  <p className="title is-2 has-text-centered">
                    Runde: {state.round}
                  </p>
                </div>
                <div className="column is-10">
                  <div className="buttons is-centered">
                    <button
                      className="button is-warning mr-10"
                      onClick={rewindLastMove}
                    >
                      Rückgängig
                    </button>

                    <button
                      className="button is-danger ml-10"
                      onClick={toggleAboortModal}
                    >
                      Spiel abbrechen
                    </button>
                  </div>
                </div>
              </div>

              <div>
                <table className="table has-text-centered is-fullwidth">
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>Punkte</th>
                      <th>Geworfen</th>
                      <th>
                        <FontAwesomeIcon icon={faShower} />
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {state.players.map((currentPlayer, index) => (
                      <PlayerRow
                        {...{
                          index,
                          state,
                          setState,
                          currentPlayer,
                          sessionStorage,
                        }}
                        key={`table-player-${index}`}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className={state.gameWrapper}>
              <div className="columns">
                <div className="column">
                  <article className="message is-success">
                    <div className="message-header">
                      <p> Diese Spieler haben es geschafft: </p>
                    </div>
                    <div className="message-body">
                      <dl>
                        {state.successfulPlayers.map((player, index) => (
                          <li key={`successful-${player.name}-${index}`}>
                            {player.name}
                            {player.wonInRound > 4 &&
                              " in Runde " + player.wonInRound}
                          </li>
                        ))}
                      </dl>
                    </div>
                  </article>
                </div>

                {state.players.length > 0 && (
                  <div className="column">
                    <article className="message ">
                      <div className="message-header">
                        <p>Diese haben es nicht geschafft:</p>
                      </div>
                      <div className="message-body">
                        <dl>
                          {state.players.map((player, index) => (
                            <li key={`unsuccessful-${player.name}-${index}`}>
                              {player.name}
                            </li>
                          ))}
                        </dl>
                      </div>
                    </article>
                  </div>
                )}

                {state.showeringPlayers.length > 0 && (
                  <div className="column">
                    <article className="message is-link">
                      <div className="message-header">
                        <p>Diese Spieler sind duschen:</p>
                      </div>
                      <div className="message-body">
                        <dl>
                          {state.showeringPlayers.map((player, index) => (
                            <li key={`showering-${player.name}-${index}`}>
                              {player.name}
                            </li>
                          ))}
                        </dl>
                      </div>
                    </article>
                  </div>
                )}
              </div>

              <div className="columns is-centered">
                <div className="column is-half">
                  <button
                    className="button is-warning is-fullwidth is-medium has-text-weight-bold"
                    onClick={restartGame}
                  >
                    Erneut spielen!
                  </button>
                </div>
              </div>

              <GameHistory {...{ state }} />
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Spielen
