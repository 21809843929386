import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShower } from "@fortawesome/free-solid-svg-icons"

export const ShowerModal = ({ state, closeShowerModal }) => {
  return (
    <div className={`modal ${state.showerModalActive ? "is-active" : ""}`}>
      <div
        className="modal-background"
        onKeyDown={() => {}}
        role="button"
        tabIndex="0"
        aria-label="close image modal"
        onClick={closeShowerModal}
      ></div>
      <div className="modal-content">
        <div className="box has-text-centered">
          <p className="title is-4">
            {state.newShoweringPlayer} geht duschen! &nbsp;{" "}
            <FontAwesomeIcon icon={faShower} />
          </p>
          <button className="button is-info" onClick={closeShowerModal}>
            Schließen
          </button>
        </div>
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={closeShowerModal}
      ></button>
    </div>
  )
}
