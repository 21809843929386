import React from "react"

export const HistoryTableCell = ({ player, index }) => {
  return (
    <div>
      {player.history[index].points + " + " + player.history[index].scored}
      <hr className="is-marginless" />
      <b>
        {parseInt(player.history[index].points) +
          parseInt(player.history[index].scored)}
      </b>
    </div>
  )
}
