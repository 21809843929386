import React, { useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

export const PlayerRow = ({
  state,
  setState,
  currentPlayer,
  sessionStorage,
  index,
}) => {
  const [selectedPoints, setSelectedPoints] = useState(0)

  const handlePointSelect = (event) => setSelectedPoints(event.target.value)

  const handlePointCheckout = () => {
    sessionStorage.setItem(state.move, JSON.stringify(state))
    sessionStorage.setItem("lastKey", JSON.stringify(state))

    let newPlayersTurn = state.playersTurn
    let newRound = state.round
    let newlyShoweringPlayers = state.showeringPlayers
    let newSuccessfulPlayers = state.successfulPlayers

    let playersNewStats = state.players?.map((player) => {
      if (player.name === currentPlayer.name) {
        player.history.push({
          scored: selectedPoints,
          points: player.points,
        })

        if (parseInt(player.points) + parseInt(selectedPoints) <= 50) {
          player.points = parseInt(player.points) + parseInt(selectedPoints)
        } else {
          player.points = 25
        }
        if (parseInt(selectedPoints) < 1) {
          player.showerCounter++
        } else {
          player.showerCounter = 0
        }
        return player
      } else {
        return player
      }
    })

    if (newPlayersTurn < state.players.length - 1) {
      newPlayersTurn++
    } else {
      newPlayersTurn = 0
      newRound++

      playersNewStats.map((player) =>
        player.showerCounter >= 3
          ? newlyShoweringPlayers.push(player)
          : player.points === 50 && newSuccessfulPlayers.push(player)
      )

      playersNewStats = playersNewStats.filter(
        (player) => player.points < 50 && player.showerCounter < 3
      )

      playersNewStats.sort((a, b) => a.points - b.points)

      if (
        playersNewStats.length > 1 &&
        playersNewStats[0]?.points === playersNewStats[1]?.points &&
        Math.random() >= 0.5
      ) {
        const [player_one, player_two, ...rest] = playersNewStats
        playersNewStats = [player_two, player_one, ...rest]
      }
    }

    setState({
      ...state,
      starterModalActive: playersNewStats.length > 1 && newPlayersTurn === 0,
      move: state.move + 1,
      players: playersNewStats,
      playersTurn: newPlayersTurn,
      prepareGameControlsActive: false,
      showerModalActive: currentPlayer.showerCounter >= 3,
      newShoweringPlayer: currentPlayer.name,
      newWinner: currentPlayer.name,
      winnerModalActive: currentPlayer.points === 50,
      round: newRound,
      showeringPlayers: newlyShoweringPlayers,
      successfulPlayers: newSuccessfulPlayers,
    })
  }

  return (
    <tr className={index === state.playersTurn ? "is-selected" : ""}>
      <th
        className={
          currentPlayer?.showerCounter >= 2
            ? "has-text-danger"
            : currentPlayer?.points >= 38
              ? "has-text-warning"
              : ""
        }
        style={{
          textShadow: "0px 0px 16px rgba(0, 0, 0, 0.3)",
          textDecoration:
          currentPlayer?.showerCounter === 3 ? "line-through" : "none",
        }}
      >
        {currentPlayer?.name}
      </th>
      <td
        className={`${
          currentPlayer?.showerCounter >= 2
            ? "has-text-danger"
            : currentPlayer?.points >= 38
            ? "has-text-warning"
            : ""
        }`}
        style={{
          textShadow: "0px 0px 16px rgba(0, 0, 0, 0.3)",
        }}
      >
        {currentPlayer?.points}
      </td>
      <td>
        <div
          className={`select ${
            currentPlayer?.showerCounter >= 2
              ? "is-danger"
              : currentPlayer?.points >= 38
              ? "is-warning"
              : ""
          }`}
          style={{
            textShadow: "0px 0px 16px rgba(0, 0, 0, 0.3)",
            marginRight: "10px",
          }}
        >
          <select
            className={
              currentPlayer?.showerCounter >= 2
                ? "has-text-danger"
                : currentPlayer?.points >= 38
                ? "has-text-warning"
                : ""
            }
            disabled={index !== state.playersTurn}
            name="Geworfene Punkte"
            onChange={handlePointSelect}
            onBlur={() => {}}
            value={selectedPoints}
          >
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </select>
        </div>
        <button
          className={`button is-white ${
            currentPlayer?.showerCounter >= 2
              ? "has-text-danger"
              : currentPlayer?.points >= 38 && "has-text-warning"
          } `}
          onClick={handlePointCheckout}
          disabled={index !== state.playersTurn}
        >
          <span className="icon is-small">
            <FontAwesomeIcon icon={faCheck} />
          </span>
        </button>
      </td>

      <td
        className={`${
          currentPlayer?.showerCounter >= 2
            ? "has-text-danger"
            : currentPlayer?.points >= 38 && "has-text-warning"
        }`}
        style={{
          textShadow: "0px 0px 16px rgba(0, 0, 0, 0.3)",
        }}
      >
        {3 - currentPlayer?.showerCounter}
      </td>
    </tr>
  )
}
