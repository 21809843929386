import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAward } from "@fortawesome/free-solid-svg-icons"

export const WinnerModal = ({ state, closeWinnerModal }) => {
  return (
    <div className={`modal ${state.winnerModalActive ? "is-active" : ""}`}>
      <div
        className="modal-background"
        onKeyDown={() => {}}
        role="button"
        tabIndex="0"
        aria-label="close image modal"
        onClick={closeWinnerModal}
      ></div>
      <div className="modal-content">
        <div className="box has-text-centered">
          <p className="title is-4">
            {state.newWinner} hat es geschafft! &nbsp;{" "}
            <FontAwesomeIcon icon={faAward} />
          </p>

          <button className="button is-info" onClick={closeWinnerModal}>
            Schließen
          </button>
        </div>
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={closeWinnerModal}
      ></button>
    </div>
  )
}
