import React from "react"

export const PreGamePlayercard = ({ state, setState, player }) => {
  const removePlayer = () => {
    let playersAfterRemove = state.players.filter(
      ({ name }) => name !== player.name
    )
    setState({ ...state, players: playersAfterRemove })
  }

  return (
    <div className="column is-one-third" key={player?.name}>
      <div className="notification is-warning">
        <button
          className="delete"
          onClick={removePlayer}
          label="Spieler entfernen"
          aria-label="Spieler entfernen"
        ></button>
        <p className="title is-3">{player.name}</p>
      </div>
    </div>
  )
}
